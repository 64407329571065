
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import Footer from '@/components/footer/index.vue'
export default {
    name: 'online',
    components: {
        homeTitlepc,
        Footer,
    },

    mounted () {
     
    },

    created(){
        if(this.$route.query.type !=undefined){
            this.active=this.$route.query.type
        }

       

    },

    methods: {
        changeActive(v){
            this.active=v
        }
      

    },

    data () {
        return {
            config,
            active:0,
            registerList:[
                {
                    name:'关于我们'
                },
                {
                    name:'公司资质'
                },
                {
                    name:'合作伙伴'
                },
                {
                    name:'媒体报道'
                },
                {
                    name:'联系我们'
                },

            ]
           
            
        }
    }
}
